<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" more-btn="高级搜索" :fold-search.sync="showFoldSearch" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="合同编号">
              <el-input v-model="searchForm.contractId" placeholder="请输入要搜索的合同编号" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="合同方">
              <el-input v-model="searchForm.enterpriseName" placeholder="请输入要搜索的合同方" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="合同状态">
              <el-select v-model="searchForm.signState" placeholder="请选择要搜索的状态" filterable>
                <el-option label="全部" value="" />
                <el-option v-for="item in constants.allFinishState" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="签署方式">
              <el-select v-model="searchForm.signingMethod" placeholder="请选择要搜索的合同类型" filterable>
                <el-option label="全部" value="" />
                <el-option v-for="item in constants.signingMethodType" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="6">
            <el-form-item label="合同名称">
              <el-input v-model="searchForm.contractName" placeholder="请输入要搜索的合同名称" />
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <ics-table-inner v-loading="loading.list" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-column-btn="true" :hide-export-btn="true" @changePageNum="changePageNum">
        <template slot="table-columns-before">
          <el-table-column fixed="left" label="序号" type="index" :index="indexMethod" />
          <el-table-column prop="contractId" label="合同编号" min-width="140" :formatter="utils.isEffective" />
          <el-table-column prop="contractName" label="合同名称" min-width="140" :formatter="utils.isEffective" />
          <el-table-column prop="creditCode" label="授信编码" min-width="140" :formatter="utils.isEffective" />
          <el-table-column prop="useCode" label="用信编码" min-width="140" :formatter="utils.isEffective" />
          <el-table-column label="签署信息" min-width="230">
            <template v-slot="scope">
              <span v-for="(item, index) in scope.row.signInfoList" :key="index">
                <p>{{ item.orgName }} {{ item.signatory }} - {{ item.signStateName }}</p>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="signingMethod" label="签署方式" min-width="140" :formatter="(row, col, cell) => utils.statusFormat(cell, 'signingMethodType')" />
        </template>
        <template slot="table-columns-after">
          <el-table-column fixed="right" label="操作" width="100">
            <template v-slot="scope">
              <el-link v-if="scope.row.signState === 1" type="primary" class="text-btn" @click="utils.downloadP('concat', scope.row.afterSigninUrlFileId)">
                下载
              </el-link>
              <el-link type="primary" class="text-btn" @click="$router.push({ name: 'signDetail', query: { contractId: scope.row.contractId } })">
                查看
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ics-table-inner>
    </div>
  </el-main>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
import utils from '@/assets/js/utils'
export default {
  components: {},
  mixins: [basePageListMixin],
  data () {
    return {
      showFoldSearch: false,
      baseSearchForm: {
        contractId: '',
        contractName: '',
        enterpriseName: '',
        contractType: '',
        signState: '',
        signingMethod: '',
        afterContractDateEnd: '',
        beforeContractDateEnd: ''
      },
      columnOption: [
        { label: '状态', prop: 'signState', formatter: (row, col, cell) => this.utils.statusFormat(cell, 'allFinishState'), minWidth: 100 },
      ]
    }
  },
  computed: {
    afterContractArr: utils.computedDate('afterContractDateEnd', 'beforeContractDateEnd')
  },
  methods: {
    getList () {
      this.loading.list = true
      this.api.contract.sign.pageList(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    }
  }
}
</script>

<style>

</style>
